export const getLabelPaymentChargebackStatus = (status) => {
  if (status == 'pending') {
    return 'Aguardando aprovação';
  }
  if (status == 'requested') {
    return 'Estorno solicitado';
  }

  if (status == 'refunded') {
    return 'Estorno realizado';
  }

  if (status == 'canceled') {
    return 'Rejeitado';
  }

  if (status == 'paid') {
    return 'Pago';
  }

  if (status == 'failed') {
    return 'Falhou';
  }

  return '';
}

export const getLabelInvoiceStatus = (invoice) => {
  let label = 'A vencer';

  if (invoice.attributes && invoice.attributes.is_canceled) {
    label = 'Cancelado';
  }

  if (invoice.attributes && invoice.attributes.is_paid) {
    label = 'Pago';
  }

  if (invoice.attributes && invoice.attributes.is_expired) {
    label = 'Vencido';
  }

  if (invoice.relationships && invoice.relationships.status && invoice.relationships.status.attributes.name == 'refunded') {
    label = 'Estornado';
  }

  return label;
}

export const getColorInvoiceStatus = (invoice) => {
  if (invoice.attributes && invoice.attributes.is_pending) {
    return 'warning';
  }

  if (invoice.attributes && (invoice.attributes.is_canceled || invoice.attributes.is_expired)) {
    return 'danger';
  }

  if (invoice.relationships && invoice.relationships.status && invoice.relationships.status.attributes.name == 'refunded') {
    return 'danger';
  }

  if (invoice.attributes && invoice.attributes.is_paid) {
    return 'success';
  }

  return 'primary';
}


// export const getColorInvoiceStatus = (status) => {
//   if (status == 'pending') {
//     return 'warning';
//   }

//   if (status == 'paid') {
//     return 'success';
//   }

//   if (status == 'canceled' || status == 'refunded') {
//     return 'danger';
//   }

//   return 'primary';
// }

export const getColorPaymentChargebackStatus = (status) => {
  if (status == 'pending') {
    return 'warning';
  }

  if (status == 'paid' || status == 'refunded') {
    return 'success';
  }

  if (status == 'canceled') {
    return 'danger';
  }

  return 'primary';
}

export const getLabelCreditType = (value) => {
  if (value == 'branch') {
    return 'Criado pelo campo';
  }

  if (value == 'purchase') {
    return 'Compra pelo app';
  }

  if (value == 'refund') {
    return 'Reposição';
  }

  return '';
}

export const getLabelPaymentChargebackOrigin = (value) => {
  if (value == 'branch') {
    return 'Campo';
  }

  if (value == 'client') {
    return 'Cliente';
  }

  return value;
}

export const getPaymentTypeLabel = (value) => {
  const paymentOnlineMethods = [
    { label: 'Cartão de crédito', value: 'credit_card' },
    { label: 'Pix', value: 'pix' },
  ];

  let f = paymentOnlineMethods.find(type => type.value == value);

  let label = value;

  if (f) {
    label = f.label;
  }

  return label;
}


export const getColorPaymentStatus = (status) => {
  if (status == 'pending' || status == 'requested') {
    return 'warning';
  }

  if (status == 'paid') {
    return 'success';
  }

  if (status == 'canceled' || status == 'failed' || status == 'refunded') {
    return 'danger';
  }

  return 'primary';
}

export const getLabelPaymentStatus = (status) => {
  if (status == 'pending') {
    return 'Aguardando pagamento';
  }
  if (status == 'requested') {
    return 'Processando';
  }

  if (status == 'refunded') {
    return 'Estornado';
  }

  if (status == 'canceled') {
    return 'Cancelado';
  }

  if (status == 'paid') {
    return 'Pago';
  }

  if (status == 'failed') {
    return 'Falhou';
  }

  return status;
}

export const getBrandImage = (value) => {
  if (value.toUpperCase() == 'PIX') {
    return '/media/payment/image_pix.png';
  }

  if (value.toUpperCase() == 'VISA') {
    return '/media/payment/image_card_visa.png';
  }

  if (value.toUpperCase() == 'DINERS') {
    return '/media/payment/image_card_diners.png';
  }

  if (value.toUpperCase() == 'DISCOVER') {
    return '/media/payment/image_card_discover.png';
  }

  if (value.toUpperCase() == 'AMEX') {
    return '/media/payment/image_card_amex.png';
  }

  if (value.toUpperCase() == 'ELO') {
    return '/media/payment/image_card_elo.png';
  }

  if (value.toUpperCase() == 'MASTERCARD') {
    return '/media/payment/image_card_mastercard.png';
  }

  if (value.toUpperCase() == 'HIPERCARD') {
    return '/media/payment/image_card_hipercard.png';
  }

  if (value.toUpperCase() == 'JCB') {
    return '/media/payment/image_card_jcb.png';
  }

  return '/media/payment/image_creditCard.png';
}
